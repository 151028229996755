import { menuAnatomy, tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, extendTheme } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const {
  definePartsStyle: definePartsStyleTabs,
  defineMultiStyleConfig: defineMultiStyleConfigTabs,
} = createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const baseMenuStyle = definePartsStyle({
  list: {
    // this will style the MenuList component
    borderRadius: "lg",
    boxShadow: "md",
  },
  item: {
    // this will style the MenuItem and MenuItemOption components
    py: "2",
    _hover: {
      bg: "neutral.50",
    },
    _focus: {
      bg: "neutral.50",
    },
  },
});
// export the base styles in the component theme
const menuTheme = defineMultiStyleConfig({ baseStyle: baseMenuStyle });

// define the base component styles
const baseTabsStyle = definePartsStyleTabs({
  tabpanel: {
    px: 0,
    py: 6,
  },
});
// export the base styles in the component theme
const tabsTheme = defineMultiStyleConfigTabs({ baseStyle: baseTabsStyle });

export const chakraTheme = extendTheme({
  initialColorMode: "system",
  useSystemColorMode: true,
  colors: {
    primary: {
      50: "#F0EFFA",
      100: "#DCDCF4",
      200: "#BAB9E9",
      300: "#9796DE",
      400: "#7977D4",
      500: "#5453C9",
      600: "#3937AF",
      700: "#2A2880",
      800: "#1C1B55",
      900: "#0E0D2B",
    },
    secondary: {
      50: "#FEF5EB",
      100: "#FEECD7",
      200: "#FDD9B0",
      300: "#FBC688",
      400: "#FAB361",
      500: "#F9A038",
      600: "#EE8207",
      700: "#B26205",
      800: "#774104",
      900: "#3B2102",
    },
    green: {
      50: "#dffdf2",
      100: "#baf2de",
      200: "#92e9c9",
      300: "#6adfb5",
      400: "#42d6a0",
      500: "#29bd87",
      600: "#1c9369",
      700: "#0f694a",
      800: "#02402c",
      900: "#00170c",
    },
    red: {
      25: "#FFF2F1",
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
      950: "#450a0a",
    },
    gray: {
      0: "#FFFFFF",
      25: "#F8FAFC",
      50: "#F1F5F9",
      100: "#E2E8F0",
      200: "#CBD5E1",
      300: "#94A3B8",
      400: "#64748B",
      500: "#475569",
      600: "#334155",
      700: "#1E293B",
      800: "#0F172A",
      900: "#020617",
    },
    neutral: {
      0: "#FFFFFF",
      25: "#F8FAFC",
      50: "#F1F5F9",
      100: "#E2E8F0",
      200: "#CBD5E1",
      300: "#94A3B8",
      400: "#64748B",
      500: "#475569",
      600: "#334155",
      700: "#1E293B",
      800: "#0F172A",
      900: "#020617",
    },
  },
  shadows: {
    // To be changed to primary
    outline: "0 0 0 1px #3182ce",
    border: "0 0 0 1px #E2E8F0",
    xs: "2px 2px 4px 0 rgb(0 0 0 / 2%)",
    sm: "0 2px 6px 0 rgb(0 0 0 / 4%)",
    md: "0 6px 24px 0 rgb(0 0 0 / 6%)",
  },
  sizes: {
    container: {
      sm: "28rem",
      md: "36rem",
      lg: "50rem",
    },
  },
  components: {
    Heading: {
      baseStyle: {
        color: "neutral.900",
        fontWeight: "semibold",
        letterSpacing: "-0.04em",
      },
      sizes: {
        heading1: {
          fontSize: "2.5rem",
          lineHeight: 1.1,
        },
        heading2: {
          fontSize: "2.25rem",
          lineHeight: 1.1,
        },
        heading3: {
          fontSize: "2rem",
          lineHeight: 1.12,
        },
        heading4: {
          fontSize: "1.75rem",
          lineHeight: 1.15,
        },
        heading5: {
          fontSize: "1.5rem",
          lineHeight: 1.15,
        },
        heading6: {
          fontSize: "1.25rem",
          lineHeight: 1.2,
        },
      },
    },
    Text: {
      baseStyle: {
        color: "neutral.400",
      },
    },
    FormLabel: {
      baseStyle: {
        color: "neutral.500",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "medium",
        lineHeight: 1.4,
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderWidth: 1,
        },
      },
    },
    Menu: menuTheme,
    Tabs: tabsTheme,
  },
  styles: {
    global: {
      body: {
        backgroundColor: "neutral.25",
      },
      p: {
        whiteSpace: "pre-line",
      },
    },
  },
  semanticTokens: {
    colors: {
      "chakra-placeholder-color": { _light: "neutral.300" },
      "chakra-border-color": { _light: "neutral.100" },
    },
  },
});
