import { combineReducers } from "redux";
import addGuestReducer from "./addGuest";
import configureCardReducer from "./configureCard";
import createCampaignReducer from "./createCampaign";
import createEventReducer from "./createEvent";
import createProjectReducer from "./createProject";
import createQuestionReducer from "./createQuestion";
import editEventReducer from "./editEvent";
import editGuestReducer from "./editGuest";
import editQuestionReducer from "./editQuestion";
import groupGuestReducer from "./groupGuest";
import guestChatReducer from "./guestChat";
import inviteMemberReducer from "./inviteMember";
import overrideAnswersReducer from "./overrideAnswers";
import sendMessageReducer from "./sendMessage";
import sendTestMessageReducer from "./sendTestMessage";
import uploadGuestsReducer from "./uploadGuests";

const modalsReducer = combineReducers({
  addGuest: addGuestReducer,
  configureCard: configureCardReducer,
  createCampaign: createCampaignReducer,
  createEvent: createEventReducer,
  createProject: createProjectReducer,
  createQuestion: createQuestionReducer,
  editEvent: editEventReducer,
  editGuest: editGuestReducer,
  editQuestion: editQuestionReducer,
  groupGuest: groupGuestReducer,
  guestChat: guestChatReducer,
  inviteMember: inviteMemberReducer,
  overrideAnswers: overrideAnswersReducer,
  sendMessage: sendMessageReducer,
  sendTestMessage: sendTestMessageReducer,
  uploadGuests: uploadGuestsReducer,
});

export default modalsReducer;
