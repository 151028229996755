import { makeActionCreator, makeAsyncActionCreator } from "redux-toolbelt";
import api from "../../Api";
import { withDelay } from "../utils";

const makeGuestsAction = makeActionCreator.withDefaults({
  prefix: "guests/",
});

const makeAsyncGuestsAction = makeAsyncActionCreator.withDefaults({
  prefix: "guests/",
});

export const resetGuests = makeGuestsAction("RESET_GUESTS");
export const fetchGuests = makeAsyncGuestsAction("FETCH_GUESTS");
export const addGuest = makeAsyncGuestsAction("ADD_GUEST");
export const deleteGuest = makeAsyncGuestsAction("DELETE_GUEST");
export const updateGuest = makeAsyncGuestsAction("UPDATE_GUEST");

export const fetch = ({ projectId }) => {
  return async (dispatch) => {
    dispatch(fetchGuests());

    try {
      const guests = await withDelay(api.guests.list({ projectId }));

      dispatch(fetchGuests.success({ guests }));
    } catch (error) {
      console.error({ error });

      dispatch(
        fetchGuests.failure({
          errorMessage: "errors:error-message-guests-list-loading-failed",
        }),
      );
    }
  };
};

export const importCsv = ({ projectId, guestsCsv }) => {
  return async (dispatch) => {
    const newGuests = await api.guests.import({ projectId, guestsCsv });

    newGuests.forEach((guest) => {
      dispatch(addGuest.success({ guest }));
    });
  };
};

export const create = ({ projectId, guest }) => {
  return async (dispatch) => {
    const newGuest = await withDelay(api.guests.create({ projectId, guest }));

    dispatch(addGuest.success({ guest: newGuest }));

    return newGuest;
  };
};

export const update = ({ projectId, guestId, fields }) => {
  return async (dispatch) => {
    const updatedGuest = await withDelay(
      api.guests.update({ projectId, guestId, fields }),
    );

    dispatch(updateGuest.success({ guestId, guest: updatedGuest }));
  };
};

export const del = ({ guestId, projectId }) => {
  return async (dispatch) => {
    await withDelay(api.guests.delete({ guestId, projectId }));

    dispatch(deleteGuest.success({ guestId }));
  };
};

export const removeFromGroup = ({ projectId, guestId }) => {
  return async (dispatch) => {
    const updatedGuests = await withDelay(
      api.guests.removeFromGroup({ projectId, guestId }),
    );

    updatedGuests.forEach(({ guestId, guest }) => {
      dispatch(updateGuest.success({ guestId, guest }));
    });
  };
};

export const addToGroup = ({ projectId, guestId, group }) => {
  return async (dispatch) => {
    const updatedGuests = await withDelay(
      api.guests.addToGroup({ projectId, guestId, group }),
    );

    updatedGuests.forEach(({ guestId, guest }) => {
      dispatch(updateGuest.success({ guestId, guest }));
    });
  };
};
