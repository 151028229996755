import { combineReducers } from "redux";
import deleteCampaignReducer from "./deleteCampaign";
import deleteEventReducer from "./deleteEvent";
import deleteGuestReducer from "./deleteGuest";
import deleteProjectReducer from "./deleteProject";
import removeGuestGroupReducer from "./removeGuestGroup";

const alertsReducer = combineReducers({
  deleteCampaign: deleteCampaignReducer,
  deleteEvent: deleteEventReducer,
  deleteGuest: deleteGuestReducer,
  deleteProject: deleteProjectReducer,
  removeGuestGroup: removeGuestGroupReducer,
});

export default alertsReducer;
